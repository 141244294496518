import { Routes, Route } from "react-router-dom";
import React from "react";

// Layout
import { Principal } from "./Layouts/Principal";
import { Autentication } from "./Layouts/Autentication";
import { Private } from "./Layouts/Private";
import { Admin } from "./Layouts/Admin";

// Components
import Loader from "./Components/Loaders";

// Public pages
const About = React.lazy(() => import("./Pages/Public/About"));
const Contact = React.lazy(() => import("./Pages/Public/Contact"));
const Home = React.lazy(() => import("./Pages/Public/Home"));
const News = React.lazy(() => import("./Pages/Public/News"));
const New = React.lazy(() => import("./Pages/Public/News/new"));

// Autentication pages
const Login = React.lazy(() => import("./Pages/Autentication/Login"));
const Register = React.lazy(() => import("./Pages/Autentication/Register"));
const Recovery = React.lazy(() => import("./Pages/Autentication/Recovery"));

// Private pages
const Dashboard = React.lazy(() => import("./Pages/Private/Dashboard"));
const Verify = React.lazy(() => import("./Pages/Private/Verify"));
const Profile = React.lazy(() => import("./Pages/Private/Profile"));
const Characterization = React.lazy(() => import("./Pages/Private/Characterization"));
const FinancialProfile = React.lazy(() => import("./Pages/Private/FinancialProfile"));

// Admin pages
const DashboardAdmin = React.lazy(() => import("./Pages/Admin/Dashboard"));
const ProfileAdmin = React.lazy(() => import("./Pages/Admin/Profile"));
const CharacterizationAdmin = React.lazy(() => import("./Pages/Admin/Characterization"));
const FinancialAdmin = React.lazy(() => import("./Pages/Admin/FinancialProfile"));
const Stadistic = React.lazy(() => import("./Pages/Admin/UserStadistic"));
const CharacterizationStatistics = React.lazy(() =>
  import("./Pages/Admin/CharacterizationStatistics")
);
const NewsAdmin = React.lazy(() => import("./Pages/Admin/News"));
const CreateNewAdmin = React.lazy(() => import("./Pages/Admin/News/AddNew"));
const EditNewAdmin = React.lazy(() => import("./Pages/Admin/News/EditNew"));

const App = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        {/* Rutas publicas */}
        <Route path="/" element={<Principal />}>
          <Route index element={<Home />} />
          <Route path="nosotros" element={<About />} />
          <Route path="contactanos" element={<Contact />} />
          <Route path="noticias" element={<News />} />
          <Route path="noticia/:slug" element={<New />} />
        </Route>

        {/* Rutas autenticacion */}
        <Route path="autenticacion" element={<Autentication />}>
          <Route index element={<Login />} />
          <Route path="registro" element={<Register />} />
          <Route path="recuperar" element={<Recovery />} />
        </Route>

        {/* Rutas privadas */}
        <Route path="sistema" element={<Private />}>
          <Route index element={<Dashboard />} />
          <Route path="verificar" element={<Verify />} />
          <Route path="perfil" element={<Profile />} />
          <Route path="caracterizacion" element={<Characterization />} />
          <Route path="financiero" element={<FinancialProfile />} />
        </Route>

        {/* Rutas admin */}
        <Route path="administracion" element={<Admin />}>
          <Route index element={<DashboardAdmin />} />
          <Route path="estadisticas-usuarios" element={<Stadistic />} />
          <Route path="estadisticas-emprendimientos" element={<CharacterizationStatistics />} />
          <Route path="noticias" element={<NewsAdmin />} />
          <Route path="noticia/agregar" element={<CreateNewAdmin />} />
          <Route path="noticia/editar/:slug" element={<EditNewAdmin />} />
          <Route path="perfil/:uid" element={<ProfileAdmin />} />
          <Route path="caracterizacion/:uid" element={<CharacterizationAdmin />} />
          <Route path="financiero/:uid" element={<FinancialAdmin />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default App;
