import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "../../Provider/index";

// Components
import Loader from "../../Components/Loaders/index";
import NavbarPrivate from "./NavbarPrivate";

export const Private = () => {
  const [context, setContext] = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (context.token && context.user) {
      if (!context.user.verified) {
        navigate("/sistema/verificar");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      navigate("/autenticacion");
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {context.user.verified ? <NavbarPrivate context={context} setContext={setContext} /> : null}
      <Outlet />
    </>
  );
};
