import React from "react";

const Footer = () => {
  return (
    <div className="bg-blue-900 py-6 wp-full text-center text-white">
      <p className="text-sm ">
        2022. Emprender Juntos,{" "}
        <strong>Ministerio del Poder Popular de Economía Finanzas y Comercio Exterior</strong>
      </p>
    </div>
  );
};

export default Footer;
