import React from "react";

import { Link, useMatch, useResolvedPath } from "react-router-dom";

const Navlink = ({ children, to }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      className="nav-link text-white hover:text-gray-400 p-0"
      style={{ color: match ? "grey" : "white" }}
      to={to}
    >
      {children}
    </Link>
  );
};

export default Navlink;
