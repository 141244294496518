import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "../../Provider/index";

// Components
import Loader from "../../Components/Loaders/index";
import NavbarAdmin from "./NavbarAdmin";

export const Admin = () => {
  const [context, setContext] = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (context.token && context.user) {
      
      if (!context.user.verified) navigate("/sistema/verificar");
      else if (!context.user.is_staff) navigate("/sistema");
      else setLoading(false);

    } else {
      navigate("/autenticacion");
    }
  }, [context, navigate]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <NavbarAdmin context={context} setContext={setContext} />
      <Outlet />
    </>
  );
};
