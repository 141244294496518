import { Link } from "react-router-dom";

// Components
import Navlink from "../../../Components/Navlink";
import logo from "../../../Assets/Images/logo_letras_blancas.png";

const Navbar = () => {
  return (
    <nav className=" relative w-full flex flex-wrap items-center justify-between py-6 divide-y divide-gray-200 bg-blue-900 shadow-lg navbar navbar-expand-lg navbar-light ">
      <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
         
        
        {/* Boton responsive */}
        <button
          className=" navbar-toggler text-white border-0 hover:shadow-none hover:no-underline py-2 px-2.5  bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            className="w-6"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
        </button>

        {/* Menu */}
        <div
          className="collapse navbar-collapse flex-grow items-center"
          id="navbarSupportedContent"
        >
          <ul class="flex justify-around w-6/12">
          <li><a href="https://emprenderjuntos.gob.ve/" class="flex-shrink-0 mr-6 flex items-center"><img src={logo} alt="" className="mx-auto" width="20%"/></a></li>
        </ul>
          <ul className="navbar-nav flex flex-col pl-0 list-style-none mr-auto mx-auto uppercase text-sm">
            <li className="nav-item pr-2 my-2 font-bold">
              <Navlink to="/">Inicio</Navlink>
            </li>
            <li className="nav-item pr-2 my-2 font-bold">
              <Navlink to="nosotros">Sobre nosotros</Navlink>
            </li>
            <li className="nav-item pr-2 my-2 font-bold">
              <Navlink to="noticias">Noticias</Navlink>
            </li>
            <li className="nav-item pr-2 my-2 font-bold">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UC_49CZg6-t3lOzwhWu0sUGw"
                className="nav-link text-white hover:text-gray-400 p-0"
                rel="noreferrer"
              >
                Aula Virtual
              </a>
            </li>
            <li className="nav-item pr-2 my-2 mb-5 lg:mb-0 font-bold">
              <Navlink to="contactanos">Contáctanos</Navlink>
            </li>
          </ul>
          {/* Boton login */}
        <Link
          to="/autenticacion"
          className="px-3 py-2 bg-secondary hover:opacity-90 rounded uppercase text-white text-sm rounded-full"
        >
          Ingresar
        </Link>
        </div>

        
      </div>
    </nav>
  );
};

export default Navbar;
