import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "tw-elements";

import { BrowserRouter } from "react-router-dom";
import AppProvider from "./Provider";
import App from "./App";
const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
};

ReactDOM.render(
  <AppProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AppProvider>,
  document.getElementById("root")
);

// addScript("//js-na1.hs-scripts.com/44269021.js");
addScript("//js-na1.hs-scripts.com/44269021.js");
