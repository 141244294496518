import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

// Components
import Navlink from "../../../Components/Navlink";
import { UserIcon, MenuIcon, MenuAlt1Icon, ArrowLeftIcon } from "@heroicons/react/solid";

const NavbarAdmin = ({ context, setContext }) => {
  const [menuOpenAdmin, setMenuOpenAdmin] = useState(false);
  const navigate = useNavigate();

  // Cerrar sesion
  const handleLogOut = () => {
    setContext({
      user: {},
      token: "",
      uid: "",
    });
  };

  return (
    <nav className=" relative w-full flex flex-wrap items-center justify-between py-6 bg-primary shadow-lg navbar navbar-expand-lg navbar-light ">
      <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
        {/* Boton responsive */}
        <button
          className=" navbar-toggler text-white border-0 hover:shadow-none hover:no-underline py-2 px-2.5  bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setMenuOpenAdmin(!menuOpenAdmin)}
        >
          {!menuOpenAdmin ? (
            <MenuIcon className="text-white w-8" />
          ) : (
            <MenuAlt1Icon className="text-white w-8" />
          )}
        </button>

        {/* Menu */}
        <span onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="text-white w-5 hover:text-gray-400" />
        </span>
        <div className="collapse navbar-collapse flex-grow" id="navbarSupportedContent">
          <ul className="navbar-nav flex flex-col pl-0 list-style-none mr-auto mx-auto uppercase text-sm w-full">
            <li className="nav-item pr-2 my-2">
              <Navlink to="/administracion">Usuarios</Navlink>
            </li>
            <li className="nav-item pr-2 my-2">
              <Navlink to="/administracion/estadisticas-usuarios">Estadisticas Usuarios</Navlink>
            </li>
            <li className="nav-item pr-2 my-2">
              <Navlink to="/administracion/estadisticas-emprendimientos">
                Estadisticas Emprendimientos
              </Navlink>
            </li>
            <li className="nav-item pr-2 my-2">
              <Navlink to="/administracion/noticias">Noticias</Navlink>
            </li>
          </ul>
        </div>

        {/* Perfil - Cerrar sesion */}
        {!menuOpenAdmin ? (
          <>
            <div className="flex">
              {/* Perfil */}
              <div class="dropstart relative">
                <UserIcon
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className=" dropdown-toggle px-3 py-2 w-12 hover:opacity-50 border border-white rounded-2xl uppercase text-white text-sm duration-300"
                />
                <ul
                  class="dropdown-menu hidden bg-white rounded absolute right-1 top-14 "
                  aria-labelledby="dropdownMenuButton1s"
                >
                  {/* Opciones */}
                  <li>
                    <Link
                      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                      to="/sistema/perfil"
                    >
                      Perfil
                    </Link>
                  </li>
                  <li>
                    <button
                      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                      onClick={handleLogOut}
                    >
                      Cerrar sesion
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </nav>
  );
};

export default NavbarAdmin;
