import React, { createContext, useState, useEffect } from "react";

const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    user: {},
    token: "",
    uid: "",
  });

  return (
    <>
      <AppContext.Provider value={[state, setState]}>{children}</AppContext.Provider>
    </>
  );
};

export default AppProvider;

export const AppContext = createContext();
