import { Outlet } from "react-router-dom";

// Assets
import One from "../../Assets/Images/Login/1.jpg";
// import Logo from "../../Assets/Images/LogoThree.png";
import LogoTwo from "../../Assets/Images/logo_letras_blancas.png";
import fondoAzul from "../../Assets/Images/Login/fondo_azul.png";
export const Autentication = () => {
  return (
    <div
      class="bg-fixed bg-no-repeat bg-cover min-h-screen flex items-center justify-center gap-4"
      style={{ backgroundImage: `url(${One})` }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 w-11/12">
        <div className="flex items-center justify-center p-5">
          <h1 className="text-white font-bold text-3xl lg:text-4xl text-center">
            Crea tu usuario y realiza la caracterización de tu emprendimiento
          </h1>
        </div>
        <div className="w-full bg-primary mx-auto rounded-xl px-20 max-h-[40em] py-10 overflow-y-scroll bg-no-repeat" style={{ backgroundImage: `url(${fondoAzul})` }}>
          <img src={LogoTwo} alt="" className="mx-auto rounded-lg" />
          <Outlet />
        </div>
      </div>
    </div>
  );
};
