import React from "react";

const Loader = () => {
  return (
    <div class="flex justify-center items-center min-h-screen">
      <div className="text-center">
        <div
          class="text-secondary spinner-border animate-spin inline-block w-20 h-20 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">Cargando...</span>
        </div>
        <div className="my-2 text-gray-400 text-lg">Por favor espere</div>
      </div>
    </div>
  );
};

export default Loader;
